import AvvParser from './index'
import { getObjectEntries, uniqueArray } from '@avvoka/shared'

export function getQuestionnaireBody(
  questions: Backend.Questionnaire.IQuestion[]
) {
  const avvFormat = new AvvParser.AVVFormat()

  const questionnaires = questions.reduce<
    Record<string, Backend.Questionnaire.IQuestion[]>
  >(
    (obj, question) => {
      if (obj[question.party] == null) obj[question.party] = []
      obj[question.party].push(question)
      return obj
    },
    Object.create(null) as Record<string, Backend.Questionnaire.IQuestion[]>
  )

  for (const questionnaire of Object.keys(questionnaires)) {
    const questions = questionnaires[questionnaire]
    const builder = avvFormat.questionnaire(questionnaire)

    let lastCondition: string | undefined | null = undefined
    questions.forEach((q) => {
      const fn = builder[q.type]
      if (fn == null) {
        throw new Error(
          `Cannot save template: because type ${q.type} does not exist in questionnaire builder.`
        )
      }
      if (q.cond !== lastCondition) {
        if (lastCondition != null) {
          builder.endCondition()
          lastCondition = null
        }
        if (q.cond !== void 0) {
          lastCondition = q.cond
          builder.condition(q.cond)
        }
      }
      fn.call(builder, q)
    })
    if (lastCondition != null) builder.endCondition()
    builder.endQuestionnaire()
  }

  return avvFormat.format
}

export const getDefaultSectionQuestions = (
  questions: Backend.Questionnaire.IQuestion[]
) => {
  const firstSectionIndex = questions.findIndex((q) => q.type === 'section')
  if (firstSectionIndex === -1) return questions
  const questionsBeforeFirstSection = questions.slice(0, firstSectionIndex)
  return questionsBeforeFirstSection
}

export const moveDefaultQuestionsToTop = (
  questions: Backend.Questionnaire.IQuestion[],
  defaultQuestions: Backend.Questionnaire.IQuestion[]
) => {
  const withoutDefaultQuestions = questions.filter((q) => {
    return !defaultQuestions.some((dq) => compareQuestions(q, dq))
  })
  return defaultQuestions.concat(withoutDefaultQuestions)
}

export const compareQuestions = (
  q1: Backend.Questionnaire.IQuestion,
  q2: Backend.Questionnaire.IQuestion
) => {
  if (['section', 'label'].includes(q1.type)) {
    return q1.desc == q2.desc
  }
  return q1.att == q2.att
}

export const mergeQuestionnaires = (
  questionnaires: string[]
): Backend.Questionnaire.ParsedQuestionnaireFormat => {
  if (!questionnaires.length)
    return {} as Backend.Questionnaire.ParsedQuestionnaireFormat
  const defaultSectionQuestions = [] as Backend.Questionnaire.IQuestion[]
  const loopedQuestions = {} as Record<string, Backend.Questionnaire.IQuestion['opts']['repeater-id']>
  const body = questionnaires.reduce((out, questionnaire) => {
    const parsedQuestionnaire = AvvParser.AVVFormat.store(
      questionnaire.replaceAll('\r\n', '\n')
    )
    defaultSectionQuestions.push(
      ...getDefaultSectionQuestions(parsedQuestionnaire.questions)
    )
    parsedQuestionnaire.questions.forEach((q) => {
      if (q.opts['repeater-id'] && q.att) {
        loopedQuestions[q.att] = q.opts['repeater-id']
      }
    })
    getObjectEntries(parsedQuestionnaire).forEach(([key, value]) => {
      const isArray = Array.isArray(value)
      if (!out[key])
        out[key] = (
          isArray ? [] : {}
        ) as Utilities.IntersectionOfTypes<Backend.Questionnaire.ParsedQuestionnaireFormat>
      out[key] = isArray
        ? [...(out[key] as []), ...value]
        : { ...out[key], ...value }
    })
    return out
  }, {} as Backend.Questionnaire.ParsedQuestionnaireFormat)

  body['questionnaires'] = uniqueArray(body['questionnaires'])

  body['questions'] = moveDefaultQuestionsToTop(
    body['questions'],
    defaultSectionQuestions
  )

  body['questions'].forEach((q) => {
    if (q.att && loopedQuestions[q.att] && !q.opts['repeater-id']) {
      q.opts['repeater-id'] = loopedQuestions[q.att]
    }
  })

  body['questions'] = body['questions'].filter((q, i) => {
    const index = body['questions'].findIndex((quest) => {
      return compareQuestions(quest, q)
    })

    return index === i
  })

  return body
}
